<template>
  <div class="">
    <b-modal
      v-model="popup_quiz"
      centered
      hide-footer
      no-close-on-backdrop
      title=""
      @close="closePopup"
    >
      <div
        v-if="checkOtherQuestions.length > 0"
        class="mt-1"
      >
        <h5 class="">
          Please answer the following question(s)
        </h5>
      </div>
      <hr v-if="checkOtherQuestions.length > 0">
      <div
        v-for="(query, idx) in checkOtherQuestions"
        :key="idx+'-other'"
        class="mt-1"
      >
        <b-form-group
          v-if="!query.question.toLowerCase().includes('nric')"
          class="w-100"
          :label="query.question"
          :label-for="query.question"
        >
          <b-form-input
            :id="query.question"
            v-model="query.answer"
          />
        </b-form-group>
      </div>
      <div
        v-for="(query, index) in popup_questions"
        :key="index"
        class="mt-1"
      >
        <div
          v-if="query.question.toLowerCase().includes('nric')"
          class="w-100 mt-2"
        >
          <hr>
          <h6 class="mt-1">
            Other information required by the Organiser/Employer
          </h6>
          <hr>
          <div
            cols="12"
            class="mt-2 w-100"
          >
            <b-form-group
              class="w-100"
              :label="query.question"
              :label-for="query.question"
            >
              <b-form-input
                :id="query.question"
                v-model="query.answer"
                :state="validateNRIC(query.answer)"
              />
              <b-form-invalid-feedback>
                A valid NRIC number is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <!-- <b-form-checkbox
            v-model="save_nric"
            :disabled="!validateNRIC(query.answer)"
            class="mt-1"
          >Save NRIC number for future applications.</b-form-checkbox> -->

        </div>
      </div>

      <div class="mt-1">
        <b-form-group
          class="w-100"
          :label="`Note for Hiring Manager (Optional)`"
          :label-for="`note_from_applicant`"
        >
          <b-form-input
            :id="`note_from_applicant`"
            v-model="popup_event.note_from_applicant"
            placeholder="e.g. Upcoming surgery date"
          />
        </b-form-group>
      </div>

      <hr>

      <div class="d-flex justify-content-between my-2">
        <b-form-radio
          v-model="resume_choice"
          name="existing-resume"
          value="existing-resume"
          class="mr-1"
        >
          Use Existing Resume
        </b-form-radio>

        <b-form-radio
          v-model="resume_choice"
          name="new-resume"
          value="new-resume"
          class="ml-1"
        >
          Upload A New Resume
        </b-form-radio>
      </div>
      <div
        v-if="resume_choice === 'new-resume'"
        class="mx-auto mt-1 d-flex flex-row"
      >
        <file-pond
          ref="pond-resume"
          v-model="resumeFile"
          name="resume-upload"
          label-idle="Upload a new resume file."
          accepted-file-types="application/pdf, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          style="height: 60px!important;width: 100%;"
          :server="resumeServer"
          :files="resumeFiles"
          credits="false"
          @init="handleResumePondInit"
        />

      </div>
      <div
        v-if="resume_choice === 'existing-resume' || file_source"
        class="mt-1"
      >
        <div>
          <b-button
            v-if="file_source"
            id="#button-with-loading"
            class="shadow-md mb-1"
            style="height: 60px!important;width: 100%;"
            variant="outline-primary"
            @click="viewUploadedResume"
          >View my resume</b-button>
        </div>
        <div v-if="file_source">
          <b-overlay
            variant="white"
            :show="isLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
            style="width: 100%;"
          >

            <div
              v-if="file_source"
              class=" w-full"
              style="height: 60vh!important;"
            >
              <!-- Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file -->
              <iframe
                v-if="document_type == 'pdf'"
                class="resume-frame"
                :src="file_source"
                width="100%"
                style="height: 60vh!important;"
                frameborder="0"
                @load="handleIframeLoad"
              />

              <iframe
                v-else
                class="resume-frame"
                :src="file_source"
                width="100%"
                style="height: 60vh!important;"
                frameborder="0"
                @load="handleIframeLoad"
              />
            </div>
          </b-overlay>
        </div>

      </div>
      <div
        v-if="popup_event && Object.keys(popup_event).length > 0"
        class="mt-2"
      >
        <b-alert
          show
          variant="warning"
          icon-pack="feather"
          icon="icon-info"
          class="my-1 p-1"
        >
          <span>
            HeadHunters HQ is committed to protecting your data and data privacy. As such, we have attached the Employer/Recruiter's policy below for your reference so that you may have a peace of mind of how your data will be used and determine whether you would like to proceed with your application.
          </span>
        </b-alert>

        <b-form-checkbox
          v-if="
            $isEmpty(popup_event.event_id)
              ? $isE2i(popup_event.company_name)
              : $isE2i(popup_event.organiser_name)
          "
          id="e2iLoaAccepted"
          v-model="isE2iLoaAccepted"
          name="checkbox-1"
        >
          <span>
            I agree to the
            <!-- href="/e2i_loa"
              target="_blank"
              rel="nofollow" -->
            <b-link
              @click="
                $isEmpty(popup_event.event_id)
                  ? viewDocument(popup_event.company_loa_file, popup_event.company_loa_file_url)
                  : viewDocument(popup_event.organiser_loa_file, popup_event.organiser_loa_file_url)
              "
            >e2i’s LOA</b-link>
          </span>
        </b-form-checkbox>

        <b-form-checkbox
          v-else
          id="companyLoaAccepted"
          v-model="isCompanyLoaAccepted"
          name="checkbox-1"
        >
          <span>
            I agree to the

            <b-link
              @click="
                $isEmpty(popup_event.event_id)
                  ? viewDocument(popup_event.company_loa_file, popup_event.company_loa_file_url)
                  : viewDocument(popup_event.organiser_loa_file, popup_event.organiser_loa_file_url)
              "
            >{{
              $isEmpty(popup_event.event_id)
                ? popup_event.company_name
                : popup_event.organiser_name
            }}’s LOA</b-link>
          </span>
        </b-form-checkbox>
      </div>

      <div class="d-flex my-2 w-100">
        <b-button
          block
          :disabled="!checkIfAnswered() || !checkIfLoaAccepted()"
          class="mx-auto"
          variant="primary"
          @click="joinEvent(popup_event, $event, true)"
        >Apply Now</b-button>
      </div>
    </b-modal>

    <b-modal
      v-model="view_resume_popup"
      centered
      hide-footer
      size="lg"
      title="Resume File"
      @close="view_resume_popup = false"
    >
      <div
        class=" w-full"
        style="height: 100vh!important;"
      >
        <!-- Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file -->
        <iframe
          v-if="document_type == 'pdf'"
          :src="file_source"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />

        <iframe
          v-else
          :src="file_source"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="view_document_popup"
      centered
      hide-footer
      size="lg"
      title="Resume File"
      @close="document_source = null"
    >
      <div
        class="mx-2 w-full"
        style="height: 100vh!important;"
      >
        <iframe
          :src="`${document_src}`"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { listingsMixin } from '@/views/event-management-system/mixins/listingsMixins'
import axios from '@axios'
import { v4 as uuidv4 } from 'uuid'
import { singleUpload, deleteObjectByKey } from '@/components/awsS3'
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
)

export default {
  components: {
    FilePond,
  },
  mixins: [listingsMixin],
  props: {
    activatePopup: { type: Boolean, default: false },
    saveNric: { type: Boolean, default: false },
    jobQuestions: { type: Array, default: null },
    allApplications: null,
  },
  data() {
    return {
      applicationId: null,

      // file-pond
      resumeFiles: [],
      resumeServer: {},
      resumeFile: null, // keep track if the file-pond has a file
      isLoading: true,
      // view resume
      view_resume_popup: false,
      document_type: 'pdf',
      file_source: null,
      resume_choice: null,
      // view document
      view_document_popup: false,
      document_src: null,
      resume_file: null,
    }
  },
  computed: {
    checkOtherQuestions() {
      return this.popup_questions.filter(
        item => !item.question.toLowerCase().includes('nric'),
      )
    },
  },
  watch: {
    resumeFile(val) {
      // keep track if the file-pond has a file
      if (this.$isEmpty(val)) {
        this.resume_file = null
        this.checkIfAnswered()
      }
      this.viewUploadedResume()
    },
    async resume_choice(val, oldVal) {
      if (oldVal === 'new-resume') {
        if (!this.$isEmpty(this.resume_file)) {
          deleteObjectByKey(this.resume_file, this)
        }
        this.resume_file = null
        this.checkIfAnswered()

        this.viewUploadedResume()
      }
    },
    allApplications: {
      handler(val) {
        this.registered = this.allApplications
      },
    },
    activatePopup: {
      handler(val, oldVal) {
        if (val) {
          this.popup_quiz = true
          const event = JSON.parse(localStorage.getItem('applied_job'))
          this.popup_event = event

          this.isE2iLoaAccepted = false
          this.isCompanyLoaAccepted = false
        }
      },
    },
    jobQuestions: {
      handler(val, oldVal) {
        this.popup_questions = val
      },
    },
    saveNric: {
      handler(val, oldVal) {
        this.save_nric = val
      },
    },
    deep: true,
  },
  created() {
  },
  mounted() {
    this.registered = this.allApplications
    this.resume_choice = 'existing-resume'
    this.viewUploadedResume()
  },
  methods: {
    handleIframeLoad() {
      this.isLoading = false
    },
    closePopup(evt) {
      this.popup_quiz = false
      this.$emit('application-form', false)
    },

    handleResumePondInit() {
      this.applicationId = uuidv4()
      this.$emit('application-id', this.applicationId) // set job_application_id to server

      const outerThis = this
      this.$refs['pond-resume'][0]._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${outerThis.applicationId}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },

    async viewUploadedResume() {
      this.isLoading = true
      let resumeFile = null
      if (this.resume_choice === 'existing-resume') {
        const currentUser = this.$store.state.auth.ActiveUser
        if (currentUser) {
          resumeFile = currentUser.resume_file
        }
      } else {
        resumeFile = this.resume_file
      }

      const fileUrl = await this.$generateDownloadUrl(resumeFile)

      if (resumeFile && resumeFile.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = `https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true&random=${new Date().getTime()}`
      } else if (resumeFile && (resumeFile.endsWith('doc') || resumeFile.endsWith('docx'))) {
        this.document_type = 'docs'
        // const newUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
        // this.file_source = `https://api.headhuntershq.com/api/redirector/${newUrl}`
        this.file_source = null
      } else {
        this.file_source = null
      }

      if (this.file_source) {
        axios.get(this.file_source)
          .then(response => {
          })
          .catch(error => {
          })
      }
      // this.view_resume_popup = true
    },
    viewDocument(file = null, fileUrl = null) {
      if (file && file.toLowerCase().endsWith('pdf')) {
        this.document_type = 'pdf'
        this.document_src = `https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true&random=${new Date().getTime()}`
      } else {
        this.document_type = 'docs'
        this.document_src = `https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true&random=${new Date().getTime()}`
      }
      this.view_document_popup = true
    },
  },
}
</script>

<style>
.resume-frame{

}
</style>
