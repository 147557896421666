<template>
  <div>
    <div class="kiosk-footer-height" />
    <div
      class="d-flex flex-row flex-gap-md kiosk-footer bg-kiosk color-white text-light justify-content-between align-items-center"
    >
      <div style="max-width: 80%;">
        <h4 class="m-0 p-0 text-light">
          IT Support:
          <a
            href="https://t.me/ITSupportJSC"
            class="text-light"
          >https://t.me/ITSupportJSC</a>
        </h4>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <img
          :src="qrCode"
          class="qrcode-img"
        >
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  props: {
    kiosk: {
      type: Object,
      default: () => {
        return {
          isKiosk: false,
        }
      },
    },
  },
  data() {
    return {
      qrCode: require('@/assets/images/kiosk/ITSupportJSC.png')
    }
  },
}
</script>

<style lang="scss" scoped>
// #800e00
$kiosk-footer-height: 6rem;

.kiosk-footer-height {
  height: $kiosk-footer-height/2.5;
}

.kiosk-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  height: $kiosk-footer-height;
}

.qrcode-img {
  max-width: 4rem;
  height: auto;
}
</style>
